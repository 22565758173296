/* Layout */
.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 30px 0;
  color: white;
  background-color: black;
  z-index: 200; }
  @media screen and (max-width: 760px) {
    .header {
      position: fixed;
      top: 0;
      left: 0;
      padding: 0; } }
  .header .row {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between; }
  .header .-logo {
    position: relative;
    font-weight: 400;
    font-size: 24px;
    line-height: normal;
    font-family: 'soleil';
    letter-spacing: 3px;
    text-transform: uppercase;
    z-index: 300; }
    @media screen and (max-width: 760px) {
      .header .-logo {
        display: block;
        width: 100%;
        padding: 30px 0;
        font-size: 18px;
        text-align: left; } }
  @media screen and (max-width: 760px) {
    .header .-nav {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: flex-start;
      position: fixed;
      top: 0;
      right: 0;
      width: 100%;
      height: 100vh;
      text-align: center;
      overflow: scroll;
      background-color: black;
      transform: translateX(100%);
      transition: transform 0.25s cubic-bezier(0.47, 0, 0.745, 0.715);
      overflow: hidden; }
      .drawer-nav-is-open .header .-nav {
        transform: translateX(0); } }
  .header .-nav a {
    display: inline-block;
    margin-left: 40px;
    color: white;
    text-decoration: none; }
    .header .-nav a.button {
      color: black; }
    @media screen and (max-width: 760px) {
      .header .-nav a {
        display: block;
        margin: 30px 0; }
        .header .-nav a:first-child {
          margin-top: 80px; } }
  .header .-mobile-nav {
    display: none;
    position: relative;
    outline: 0;
    border: 0;
    background: transparent;
    appearance: none;
    cursor: pointer; }
    @media screen and (max-width: 760px) {
      .header .-mobile-nav {
        display: block;
        padding: 30px 0;
        z-index: 300; } }

.footer {
  margin-bottom: 50px; }
  .footer hr {
    margin: 70px 0 15px;
    border-top: 1px solid black;
    border-bottom: 0; }
    @media screen and (max-width: 760px) {
      .footer hr {
        margin: 40px 0 15px; } }
  .footer h1 {
    font-weight: 400;
    font-size: 24px;
    line-height: normal;
    font-family: 'soleil';
    letter-spacing: 3px;
    text-transform: uppercase;
    text-align: center;
    color: black; }
  .footer small {
    display: block;
    margin: 0 auto;
    padding: 0;
    text-align: center; }

/* Views */
.hero {
  position: relative;
  width: 100%;
  height: 800px;
  min-height: 800px;
  height: 100vh;
  background-color: black;
  color: white;
  overflow: hidden; }
  @media screen and (max-width: 760px) {
    .hero {
      height: auto;
      padding-top: 90px; } }
  .hero .row {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    height: 100%; }
    @media screen and (max-width: 760px) {
      .hero .row {
        flex-flow: column nowrap; } }
  .hero .-content {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    width: 60%; }
    @media screen and (max-width: 760px) {
      .hero .-content {
        width: 100%;
        height: auto; } }
  .hero h1 {
    margin-bottom: 40px; }
    @media screen and (max-width: 980px) {
      .hero h1 {
        margin-bottom: 10px; } }
  .hero p {
    padding-right: 40px;
    line-height: 24px; }
  .hero .-media {
    display: flex;
    align-items: center;
    justify-content: flex-end; }
    .hero .-media video {
      display: block;
      max-width: 480px;
      width: 108%;
      height: auto;
      margin-top: 45%; }
      @media screen and (max-width: 760px) {
        .hero .-media video {
          width: 100%;
          margin-top: -50px; } }
  .hero .-angle {
    position: absolute;
    bottom: -280px;
    left: 50%;
    width: 130%;
    height: 300px;
    border-top: 20px solid #FEFF5B;
    background-color: white;
    transform: rotate(-7deg) translate3d(-50%, -65%, 0); }

.what-we-do {
  overflow: hidden; }
  @media screen and (max-width: 760px) {
    .what-we-do {
      margin-top: -150px; } }
  @media screen and (max-width: 760px) {
    .what-we-do .decoration {
      display: none; } }
  .what-we-do .-content {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: center; }
  .what-we-do .row.--problems h2 {
    text-align: center; }
  .what-we-do .-problems {
    display: flex;
    flex-flow: row wrap;
    padding-bottom: 45px; }
    @media screen and (max-width: 760px) {
      .what-we-do .-problems {
        padding-bottom: 0; } }
  .what-we-do .-problem {
    width: 25%;
    padding: 0 20px;
    float: left;
    text-align: center; }
    @media screen and (max-width: 980px) {
      .what-we-do .-problem {
        width: 50%;
        margin-bottom: 40px; } }
    @media screen and (max-width: 760px) {
      .what-we-do .-problem {
        width: 100%; } }
    .what-we-do .-problem img {
      width: auto;
      height: 64px; }
    .what-we-do .-problem .-underline {
      display: block;
      margin: 0;
      padding: 0;
      width: 100%;
      height: 1px;
      background: url(images/underline-title.svg) no-repeat center/contain; }
    .what-we-do .-problem .-title {
      height: 44px;
      margin: 15px 0;
      text-transform: uppercase; }
    .what-we-do .-problem p {
      padding: 15px 0; }
  .what-we-do .row.--your-partners h2 {
    padding-bottom: 20px;
    text-align: left; }
    @media screen and (max-width: 760px) {
      .what-we-do .row.--your-partners h2 {
        text-align: center; } }
  .what-we-do .row.--your-partners {
    padding-top: 70px;
    padding-bottom: 70px; }
    @media screen and (max-width: 760px) {
      .what-we-do .row.--your-partners {
        padding-top: 0;
        padding-bottom: 0; } }
  .what-we-do .-your-partners {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    max-width: 1100px;
    width: 100%;
    margin: 0 auto; }
    @media screen and (max-width: 760px) {
      .what-we-do .-your-partners {
        flex-flow: column nowrap; } }
    .what-we-do .-your-partners p {
      width: 100%; }
      @media screen and (max-width: 760px) {
        .what-we-do .-your-partners p {
          text-align: center; } }
    .what-we-do .-your-partners .-content {
      width: 55%;
      float: right; }
      @media screen and (max-width: 760px) {
        .what-we-do .-your-partners .-content {
          width: 100%; } }
    .what-we-do .-your-partners .-image {
      position: relative;
      width: 38%;
      height: 100%;
      padding: 0;
      float: left; }
      @media screen and (max-width: 760px) {
        .what-we-do .-your-partners .-image {
          width: 100%;
          height: auto; } }
      .what-we-do .-your-partners .-image img {
        display: block;
        position: relative;
        width: 490px;
        height: auto;
        float: right;
        box-shadow: -10px -10px 0 #FEFF5B; }
        @media screen and (max-width: 760px) {
          .what-we-do .-your-partners .-image img {
            width: 100%;
            height: auto; } }
  .what-we-do .-achievements {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;
    margin: 50px 0; }
    @media screen and (max-width: 760px) {
      .what-we-do .-achievements {
        flex-flow: column nowrap;
        width: 100%; } }
  @media screen and (max-width: 760px) {
    .what-we-do .-achievement {
      width: 100%;
      margin-bottom: 10px;
      text-align: center; } }
  .what-we-do .-achievement h4 {
    display: inline-block;
    padding: 5px 10px;
    text-transform: uppercase;
    background: url(images/bg-title.svg) no-repeat center/100% 100%; }
    @media screen and (max-width: 760px) {
      .what-we-do .-achievement h4 {
        text-align: center; } }
  .what-we-do .-achievement p {
    padding-right: 25px;
    padding-left: 10px; }
  .what-we-do .row.--our-superpower {
    padding-top: 190px;
    padding-bottom: 120px; }
    @media screen and (max-width: 760px) {
      .what-we-do .row.--our-superpower {
        padding-bottom: 90px; } }
    .what-we-do .row.--our-superpower h4 {
      margin: 10px 0;
      text-transform: uppercase; }
      @media screen and (max-width: 760px) {
        .what-we-do .row.--our-superpower h4 {
          margin: 0 auto;
          text-align: center; } }
    .what-we-do .row.--our-superpower p {
      max-width: 40%; }
      @media screen and (max-width: 760px) {
        .what-we-do .row.--our-superpower p {
          max-width: 100%; } }
  .what-we-do .-our-superpower {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 60px 40px;
    border-radius: 3px;
    border: 3px solid black; }
    @media screen and (max-width: 980px) {
      .what-we-do .-our-superpower {
        flex-direction: column-reverse; } }
    @media screen and (max-width: 760px) {
      .what-we-do .-our-superpower {
        min-height: 170px;
        padding: 0;
        transform: translateY(-100%); } }
    @media screen and (max-width: 760px) {
      .what-we-do .-our-superpower .-content {
        text-align: center;
        transform: translateY(200%); } }
    .what-we-do .-our-superpower .-image {
      position: absolute;
      right: 40px;
      max-width: 570px;
      width: 100%; }
      @media screen and (max-width: 980px) {
        .what-we-do .-our-superpower .-image {
          width: 570px;
          top: -35%;
          right: -15%;
          width: 570px; } }
      @media screen and (max-width: 760px) {
        .what-we-do .-our-superpower .-image {
          top: 10%;
          right: 50%;
          width: 320px;
          transform: translateX(50%); } }
      .what-we-do .-our-superpower .-image img {
        display: block;
        width: 100%;
        height: auto; }

.our-values {
  padding: 45px 0;
  background: #FEFF5B;
  background: linear-gradient(90deg, white 0%, #feff5b 100%); }
  @media screen and (max-width: 760px) {
    .our-values {
      background: linear-gradient(180deg, white 0%, #feff5b 100%); } }
  .our-values .row.--values h2 {
    padding-top: 0;
    text-align: center; }
  .our-values .-values {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    max-width: 1100px;
    margin: 0 auto; }
    @media screen and (max-width: 980px) {
      .our-values .-values {
        justify-content: center; } }
  .our-values .-value {
    width: 20%;
    padding: 0 10px; }
    @media screen and (max-width: 980px) {
      .our-values .-value {
        width: 50%;
        margin-bottom: 40px; }
        .our-values .-value:last-child {
          margin-bottom: 0; } }
    @media screen and (max-width: 760px) {
      .our-values .-value {
        width: 100%;
        margin-bottom: 60px; } }
    .our-values .-value img {
      display: block;
      margin: 0 auto 20px; }
    .our-values .-value h4 {
      text-align: center;
      text-transform: uppercase; }

.our-services {
  overflow: hidden; }
  .our-services .row.--our-services {
    padding-top: 120px;
    padding-bottom: 90px; }
    @media screen and (max-width: 980px) {
      .our-services .row.--our-services {
        padding-top: 40px;
        padding-bottom: 40px; } }
    .our-services .row.--our-services h2 {
      padding-top: 0;
      text-align: center; }
    .our-services .row.--our-services .-content {
      display: none;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: center; }
      @media screen and (max-width: 980px) {
        .our-services .row.--our-services .-content {
          display: flex; } }
      @media screen and (max-width: 980px) {
        .our-services .row.--our-services .-content h4 {
          margin: 0 auto;
          padding-top: 20px;
          text-align: center; } }
      .our-services .row.--our-services .-content p {
        max-width: 450px;
        width: 100%; }
        @media screen and (max-width: 980px) {
          .our-services .row.--our-services .-content p {
            max-width: 100%;
            text-align: center; } }
  .our-services .-services {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    max-width: 1100px;
    margin: 0 auto;
    background-color: #EFF9FB;
    overflow: hidden; }
    @media screen and (max-width: 760px) {
      .our-services .-services {
        display: block;
        width: 100%; } }
  .our-services .-service {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: center;
    width: 25%;
    float: left;
    margin-bottom: auto;
    background-repeat: no-repeat;
    background-position: center left;
    background-size: cover; }
    .our-services .-service:after {
      content: "";
      display: block;
      padding-bottom: 100%; }
    .our-services .-service.service-2, .our-services .-service.service-8 {
      margin-bottom: 0;
      padding: 15px; }
      .our-services .-service.service-2:after, .our-services .-service.service-8:after {
        content: "";
        display: block;
        padding-bottom: 0; }
    @media screen and (max-width: 980px) {
      .our-services .-service {
        width: calc(100% / 3); } }
    .our-services .-service.service-1 {
      background-image: url(images/our-services-1.jpg); }
    .our-services .-service.service-3 {
      background-image: url(images/our-services-3.jpg); }
    .our-services .-service.service-4 {
      background-image: url(images/our-services-4.jpg); }
    .our-services .-service.service-5 {
      background-image: url(images/our-services-5.jpg); }
    .our-services .-service.service-6 {
      background-image: url(images/our-services-6.jpg); }
    .our-services .-service.service-7 {
      background-image: url(images/our-services-7.jpg); }
    @media screen and (max-width: 980px) {
      .our-services .-service.service-2 {
        display: none; }
      .our-services .-service.service-8 {
        display: none; } }
  .our-services .row.--services {
    display: flex;
    flex-flow: row nowrap; }
  .our-services ol {
    padding: 0;
    list-style: none; }
    @media screen and (max-width: 760px) {
      .our-services ol {
        margin: 0 auto; } }
    .our-services ol li span {
      font-weight: 700; }
    @media screen and (max-width: 760px) {
      .our-services ol li {
        margin: 0 auto 10px; }
        .our-services ol li span {
          display: block; } }
  .our-services .decoration {
    height: 70%; }
    @media screen and (max-width: 760px) {
      .our-services .decoration {
        display: none; } }
  .our-services .row.--production {
    padding-top: 40px;
    padding-bottom: 180px; }
    @media screen and (max-width: 760px) {
      .our-services .row.--production {
        padding-bottom: 40px; } }
  .our-services .-production {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    max-width: 1100px;
    width: 100%;
    margin: 0 auto; }
    @media screen and (max-width: 760px) {
      .our-services .-production {
        flex-flow: column-reverse nowrap; } }
    @media screen and (max-width: 760px) {
      .our-services .-production h4 {
        margin: 0 auto 10px; } }
    .our-services .-production p {
      max-width: 450px;
      width: 100%; }
      @media screen and (max-width: 760px) {
        .our-services .-production p {
          max-width: 100%;
          margin-bottom: 20px; } }
    .our-services .-production .-content {
      width: 50%;
      padding-right: 20px;
      float: left; }
      @media screen and (max-width: 980px) {
        .our-services .-production .-content {
          width: 60%; } }
      @media screen and (max-width: 760px) {
        .our-services .-production .-content {
          width: 100%;
          padding-right: 0;
          float: none;
          text-align: center; } }
    .our-services .-production .-image {
      position: relative;
      width: 55%;
      height: 100%;
      padding: 0;
      border-radius: 3px;
      float: left; }
      @media screen and (max-width: 980px) {
        .our-services .-production .-image {
          width: 45%; } }
      @media screen and (max-width: 760px) {
        .our-services .-production .-image {
          width: 100%;
          float: none;
          margin-bottom: 30px; } }
      .our-services .-production .-image img {
        display: block;
        position: relative;
        width: 740px;
        height: auto; }
        @media screen and (max-width: 760px) {
          .our-services .-production .-image img {
            width: 100%; } }
  .our-services .-fulfillment {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    position: relative;
    max-width: 1100px;
    width: 100%;
    margin: 0 auto; }
    @media screen and (max-width: 760px) {
      .our-services .-fulfillment {
        flex-direction: column; } }
    .our-services .-fulfillment .-content {
      padding-left: 40px; }
      @media screen and (max-width: 760px) {
        .our-services .-fulfillment .-content {
          position: absolute;
          width: 100%;
          padding-top: 20px;
          padding-left: 0;
          color: black;
          bottom: 0;
          transform: translateY(100%); } }
      @media screen and (max-width: 760px) {
        .our-services .-fulfillment .-content h4 {
          margin: 0 auto;
          text-align: center; } }
      .our-services .-fulfillment .-content p {
        max-width: 450px;
        width: 100%; }
        @media screen and (max-width: 760px) {
          .our-services .-fulfillment .-content p {
            max-width: 100%;
            text-align: center; } }
    .our-services .-fulfillment .-image {
      width: 70%;
      padding: 0;
      border-radius: 3px;
      border: 2px solid black; }
      @media screen and (max-width: 980px) {
        .our-services .-fulfillment .-image {
          width: 40%; } }
      @media screen and (max-width: 760px) {
        .our-services .-fulfillment .-image {
          width: 100%;
          padding: 20px; } }
      .our-services .-fulfillment .-image img {
        display: block;
        max-width: 520px;
        width: 100%;
        height: auto;
        transform: scale(1.15) translateY(30px); }
        @media screen and (max-width: 760px) {
          .our-services .-fulfillment .-image img {
            max-width: 100%;
            transform: scale(1) translateY(0); } }
  .our-services .row.--our-certifications {
    padding-top: 140px;
    padding-bottom: 140px; }
    @media screen and (max-width: 760px) {
      .our-services .row.--our-certifications {
        padding-top: 170px;
        padding-bottom: 0; } }
  .our-services .-our-certifications {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    max-width: 1100px;
    width: 100%;
    margin: 0 auto; }
    @media screen and (max-width: 760px) {
      .our-services .-our-certifications {
        flex-direction: column-reverse;
        margin-top: 20px; } }
    .our-services .-our-certifications .-content {
      padding-top: 20px;
      padding-right: 20px; }
      @media screen and (max-width: 760px) {
        .our-services .-our-certifications .-content {
          width: 100%;
          margin-bottom: 40px;
          padding-top: 20px;
          padding-right: 0; } }
      @media screen and (max-width: 760px) {
        .our-services .-our-certifications .-content h4 {
          margin: 0 auto;
          text-align: center; } }
      .our-services .-our-certifications .-content p {
        max-width: 450px;
        width: 100%; }
        @media screen and (max-width: 760px) {
          .our-services .-our-certifications .-content p {
            max-width: 100%;
            text-align: center; } }
    .our-services .-our-certifications .-image {
      max-width: 520px;
      width: auto;
      padding: 60px 20px;
      border-radius: 3px;
      border: 2px solid black; }
      @media screen and (max-width: 760px) {
        .our-services .-our-certifications .-image {
          max-width: 100%; } }
      .our-services .-our-certifications .-image img {
        display: block;
        width: 100%;
        height: auto; }
  .our-services .row.--testimonials {
    align-items: center;
    justify-content: center;
    padding-top: 90px;
    padding-bottom: 90px; }
  .our-services .-testimonials {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: center;
    max-width: 630px;
    margin: 0 auto; }
    @media screen and (max-width: 760px) {
      .our-services .-testimonials {
        flex-direction: column;
        align-items: center; } }
    .our-services .-testimonials .-image {
      max-width: 65px;
      width: 65px;
      height: 65px;
      margin-right: 20px;
      margin-bottom: 20px;
      border-radius: 65px;
      background-color: black;
      overflow: hidden;
      background: url(images/icon-user.svg) no-repeat center/cover; }
      @media screen and (max-width: 760px) {
        .our-services .-testimonials .-image {
          margin-right: 0; } }
    .our-services .-testimonials .-content {
      flex: 1;
      align-items: center; }
      .our-services .-testimonials .-content p:first-child {
        padding-top: 0; }
      @media screen and (max-width: 760px) {
        .our-services .-testimonials .-content p {
          text-align: center; } }

.contact-us {
  position: relative;
  margin-top: 90px; }
  @media screen and (max-width: 760px) {
    .contact-us {
      margin-top: 45px; } }
  .contact-us .decoration {
    height: 70%; }
    @media screen and (max-width: 760px) {
      .contact-us .decoration {
        display: none; } }
  .contact-us h2 {
    padding-top: 0; }
    @media screen and (max-width: 760px) {
      .contact-us h2 {
        text-align: center; } }
  .contact-us .row.--contact-us {
    position: relative;
    padding-top: 40px;
    padding-bottom: 70px; }
    @media screen and (max-width: 760px) {
      .contact-us .row.--contact-us {
        flex-flow: column;
        padding-top: 0;
        padding-bottom: 0; } }
  .contact-us .-contact-us {
    display: flex;
    flex-flow: row nowrap; }
    @media screen and (max-width: 760px) {
      .contact-us .-contact-us {
        flex-flow: column-reverse nowrap; } }
  .contact-us .-form {
    width: 50%; }
    @media screen and (max-width: 760px) {
      .contact-us .-form {
        width: 100%; } }
  .contact-us label {
    display: block;
    margin-bottom: 3px; }
  .contact-us input,
  .contact-us textarea {
    display: block;
    max-width: 360px;
    width: 100%;
    margin-bottom: 15px;
    padding: 16px 18px;
    font-weight: 400;
    font-size: 16px;
    font-family: 'soleil';
    letter-spacing: 0.56px;
    color: black;
    border-radius: 5px;
    border: 1px solid #979797; }
    @media screen and (max-width: 760px) {
      .contact-us input,
      .contact-us textarea {
        max-width: 100%; } }
    .contact-us input::-webkit-input-placeholder,
    .contact-us textarea::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: rgba(0, 0, 0, 0.3); }
    .contact-us input::-moz-placeholder,
    .contact-us textarea::-moz-placeholder {
      /* Firefox 19+ */
      color: rgba(0, 0, 0, 0.3); }
    .contact-us input:-ms-input-placeholder,
    .contact-us textarea:-ms-input-placeholder {
      /* IE 10+ */
      color: rgba(0, 0, 0, 0.3); }
    .contact-us input:-moz-placeholder,
    .contact-us textarea:-moz-placeholder {
      /* Firefox 18- */
      color: rgba(0, 0, 0, 0.3); }
    .contact-us input::placeholder,
    .contact-us textarea::placeholder {
      color: rgba(0, 0, 0, 0.3); }
  .contact-us textarea {
    min-height: 130px; }
  .contact-us .button {
    padding: 12px 18px;
    font-size: 14px;
    border-radius: 0; }
    @media screen and (max-width: 760px) {
      .contact-us .button {
        width: 100%; } }
  .contact-us .-map {
    width: 50%; }
    @media screen and (max-width: 760px) {
      .contact-us .-map {
        width: 100%;
        margin-top: 0;
        margin-bottom: 40px; } }
  .contact-us .-map-google {
    width: 100%;
    height: 100%; }
    @media screen and (max-width: 760px) {
      .contact-us .-map-google {
        height: 500px; } }
  .contact-us .-map-info {
    position: relative;
    max-width: 440px;
    width: 95%;
    margin: 0 auto;
    padding: 20px 35px;
    text-align: center;
    border-radius: 3px;
    border: 1px solid black;
    background-color: white;
    transform: translateY(-50%);
    z-index: 100; }
    @media screen and (max-width: 760px) {
      .contact-us .-map-info {
        margin: 20px auto 0;
        transform: translateY(0); } }
    .contact-us .-map-info p {
      padding: 0; }
      .contact-us .-map-info p a {
        color: black;
        text-decoration: none; }
        .contact-us .-map-info p a:hover {
          border-bottom: 1px solid black; }
      .contact-us .-map-info p span {
        -webkit-text-stroke-width: 0.25px;
        -webkit-text-stroke-color: black; }

h1,
h2,
h3,
h4,
p {
  margin: 0;
  padding: 0; }

h1 {
  font-weight: 700;
  font-size: 56px;
  line-height: 70px;
  font-family: 'lato';
  letter-spacing: 1.96px; }
  @media screen and (max-width: 980px) {
    h1 {
      font-size: 30px;
      line-height: 38px; } }

h2 {
  padding: 40px 0;
  font-weight: 700;
  font-size: 36px;
  line-height: 42px;
  font-family: 'lato';
  letter-spacing: 1.26px; }
  h2 .--light {
    font-weight: 400; }
  @media screen and (max-width: 760px) {
    h2 {
      font-size: 24px;
      line-height: 28px; } }

h3 {
  font-weight: 400;
  font-size: 32px;
  font-family: 'lato';
  letter-spacing: 1.12px; }

h4,
label {
  font-weight: 700;
  font-size: 14px;
  font-family: 'soleil';
  letter-spacing: 1px;
  text-transform: uppercase; }

a {
  font-weight: 400;
  font-size: 16px;
  font-family: 'lato';
  letter-spacing: 1px; }

p,
small {
  padding: 10px 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  font-family: 'soleil';
  letter-spacing: 0.56px; }
  p.--small,
  small.--small {
    font-size: 14px;
    line-height: 28px; }
  p.--large,
  small.--large {
    font-size: 24px;
    line-height: 32px; }
    @media screen and (max-width: 980px) {
      p.--large,
      small.--large {
        font-size: 18px;
        line-height: 24px; } }
  p a,
  small a {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    font-family: 'soleil';
    letter-spacing: 0.56px; }
  p span,
  small span {
    -webkit-text-stroke-width: 0.25px;
    -webkit-text-stroke-color: black; }

ol {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  font-family: 'soleil';
  letter-spacing: 0.56px; }
  ol li {
    margin: 5px; }
    ol li span {
      margin-right: 5px;
      font-size: 14px; }

.button {
  padding: 8px 18px;
  font-weight: 700;
  font-size: 16px;
  font-family: 'soleil';
  letter-spacing: 1px;
  color: black;
  outline: 0;
  border: 0;
  border-radius: 3px;
  background-color: #FEFF5B; }

.turbolinks-progress-bar {
  background-color: #A18100; }

body {
  margin: 0;
  padding: 0;
  background-color: white;
  color: black; }
  body.drawer-nav-is-open {
    touch-action: none;
    overflow-y: hidden; }

body * {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent; }

.column {
  position: relative;
  width: 100%;
  height: auto; }
  .column.--black {
    color: white;
    background-color: black; }
  .column.--blue {
    color: black;
    background-color: #EFF9FB; }

.row {
  max-width: 1260px;
  width: 100%;
  padding: 0 30px;
  margin: 0 auto; }
  @media screen and (max-width: 980px) {
    .row {
      padding: 0 15px; } }

.decoration {
  position: absolute;
  top: 0;
  width: 35%;
  height: 100%; }
  .decoration.--blue {
    background-color: #EFF9FB; }
  .decoration.--yellow {
    background-color: #FEFF5B; }
  .decoration.--black {
    background-color: black; }
  .decoration.--left {
    left: 0; }
  .decoration.--right {
    right: 0; }
